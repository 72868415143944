import { useEffect, useRef } from 'react';
import CocktailsImg from '../img/restaurants/iggirezekne/iggir_6.jpg';
import { useTranslation } from "react-i18next";

export default function Cocktails() {
    const anchorRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        if(window.location.href.includes('kokteili')){
            anchorRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [])

    return(
        <div className="container" style={{marginTop: '170px'}} id="cocktails" ref={anchorRef}>
            <div className="homepageContent homepageContentImg" style={{alignItems: 'flex-start'}}>
                <div className="homepageText">
                    <h2>{ t('cocktails.title') }</h2>
                    <p>{ t('cocktails.description') }</p>
                </div>
                <div className="homepageImg">
                    <img src={CocktailsImg} alt="IGGI kokteiļi" style={{objectPosition: 'bottom'}} />
                </div>
            </div>
        </div>
    )
}