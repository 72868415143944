import iggiLogo from '../img/logo/iggi.png';
import osteriaLogo from '../img/logo/osteria.svg';

import { Link } from 'react-router-dom';
import HomepageBlock from './HomepageBlock';
import AboutUs from './AboutUs';
import Footer from './Footer';
import Cocktails from './Cocktails';
import Faq from './Faq';
import Topbar from './Topbar';
import { useGetTableData } from './TableStatus';
import United from './United';
import Bus from './bus';

const Homepage = () => {
    document.title = 'IGGI bārs & karbonādes | Kokteiļi, karbonādes, burgeri un mūzika';
    const tableData = useGetTableData();

    return(
        <>
            <Topbar />
            <div className="blocks" id="home">
                <Link
                    to="/iggirezekne"
                    className="block first-block"
                    >
                    <HomepageBlock tableData={tableData} logo={iggiLogo} city="Rēzekne" phone="22 107 107" address="Atbrīvošanas aleja 95C" rest="iggirezekne"/>
                </Link>
                <Link
                    to="/osteria"
                    className="block second-block"
                    >
                    <HomepageBlock
                        tableData={tableData}
                        logo={osteriaLogo}
                        city="Rēzekne"
                        address="Atbrīvošanas aleja 142"
                        rest="osteria"
                    />
                </Link>
                <Link
                    to="/iggidaugavpils"
                    className="block third-block"
                    >
                    <HomepageBlock tableData={tableData} logo={iggiLogo} city="Daugavpils" phone="28 008 600" address="Rīgas iela 22" rest="iggidaugavpils" />
                </Link>
            </div>
            <AboutUs />
            <Cocktails />
            <Bus />
            <United />
            <Faq />
            <Footer />
        </>
    )
}

export default Homepage;