import bus_3 from '../img/bus/bus_3.jpg';
import bus_4 from '../img/bus/bus_4.jpg';
import phoneIcon from "../icons/phone.svg";
import { useRef } from 'react';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";

export default function Bus() {
    const anchorRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        if(window.location.href.includes('izbraukumi')){
            anchorRef.current.scrollIntoView({ behavior: 'smooth' });
        }

      }, []);

    return(
        <div className="container" style={{marginTop: '170px'}} id="bus" ref={anchorRef}>
            <div className="bus">
                <div className="busTop">
                    <div className="busText">
                        <h2>{ t('bus.title') }</h2>
                        <p dangerouslySetInnerHTML={{ __html: t('bus.description') }} />
                        <div className="busContacts">
                            <div>
                                <img src={ phoneIcon } alt="Adrese" />
                                <span>+371 28691510 (Raimonds)</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="busImgs">
                    <img src={bus_3} alt="IGGI Izbraukumi" />
                    <img src={bus_4} alt="IGGI Izbraukumi" />
                </div>
            </div>
        </div>
    )
}