import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import expandIcon from '../icons/expand.svg';
import faqData from '../data/faq.json';
import { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";

export default function Faq() {
    const anchorRef = useRef(null);
    const { t, i18n} = useTranslation();

    useEffect(() => {
        if(window.location.href.includes('buj')){
            anchorRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [])
    
    return (
        <div className="container" id="faq" ref={anchorRef}>
            <div className="homepageContent" style={{maxWidth: '900px'}}>
                <div className="homepageText">
                    <h2 style={{textAlign: 'center'}}>{ t('faq') }</h2>
                    <Accordion>
                        {
                            Object.values(faqData).map((values, key) => {
                                return(
                                    <AccordionItem key={key} header={
                                        <div className="accordionHeader">
                                            <h3>{values.question[i18n.language]}</h3>
                                            <img src={expandIcon} alt="Atvērt" />
                                        </div>
                                    }
                                    className="accordionItem"
                                    buttonProps={{
                                        className: ({ isEnter }) => (isEnter ? "accordionBtn accordionBtnActive" : "accordionBtn")
                                      }}
                                    >
                                    <p style={{padding: '7px 10px'}}>{values.answer[i18n.language]}</p>
                                    </AccordionItem>
                                )
                            })
                        }
                    </Accordion>
                </div>
            </div>
        </div>
    );
  }